import { supporterTokenMetadataQuery } from "@/api/supporter";
import { centsToDollars } from "@/utils/currency";
import { formatCents, formatWholeNumber } from "@/utils/i18n";
import { createGlobalState, useGlobalState } from "@givenwell/hooks";
import { useQuery } from "@tanstack/react-query";

const storedCurrency = localStorage.getItem("preferred-currency");
const initialCurrency = storedCurrency === "token" ? "token" : "dollar";

const currencyViewState = createGlobalState<"token" | "dollar">(initialCurrency);

currencyViewState.subscribe(() => {
  localStorage.setItem("preferred-currency", currencyViewState.getValue());
});

/**
 * Get whether a supporter user prefers to see money in tokens or dollars.
 */
export function usePreferredCurrencyState() {
  return useGlobalState(currencyViewState);
}

export function useSupporterMoneyFormatter(supporterId: string) {
  const [currency] = usePreferredCurrencyState();
  const { data: tokenMeta } = useQuery(supporterTokenMetadataQuery(supporterId));

  return (tokens: number) => {
    if (currency === "dollar" && tokenMeta) {
      return {
        amount: formatCents(tokens * tokenMeta.cents_per_token, tokenMeta.paired_currency),
        amountAsNumber: centsToDollars(tokens * tokenMeta.cents_per_token, tokenMeta.paired_currency),
        currency: tokenMeta.paired_currency,
      };
    } else {
      return {
        amount: formatWholeNumber(tokens),
        amountAsNumber: tokens,
        currency: tokens === 1 ? "token" : "tokens",
      };
    }
  };
}
